import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import compose from "recompact/compose";
import classnames from "classnames";
import styles from "./prices-page.scss";
import appStyles from "containers/App/app.scss";
import HamburgerIcon from "components/HamburgerIcon";
import withApplicationState from "hoc/withApplicationState";
import PriceCategoryList from "components/PriceCategoryList";

const propTypes = {
  active: bool,
  isPricesPageActive: bool,
  setPricesPageActive: func
};
const defaultProps = { active: false };

class PriceList extends PureComponent {
  static propTypes = propTypes;
  static defaultProps = defaultProps;
  container = React.createRef();
  htmlElement;
  mainElement;
  state = { mounted: false, scrollY: 0 };

  /**
   * Set body block scroll state
   * @param {boolean} active
   */
  setBodyBlockScrollState = active => {
    if (!this.htmlElement || !this.mainElement) {
      return false;
    }

    if (active) {
      setTimeout(() => this.htmlElement.classList.add("block-scroll"), 600);
    } else {
      this.htmlElement.classList.remove("block-scroll");
    }
  };

  componentDidMount() {
    this.htmlElement = document.querySelector("html");
    this.mainElement = document.querySelector(`.${appStyles.main}`);
    setTimeout(() => {
      this.setBodyBlockScrollState(this.props.active);
      this.setState({ mounted: true });
    }, 0);
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.active !== this.props.active) {
      if (window && this.props.active) {
        this.setState({ scrollY: window.scrollY });
      }
      this.setBodyBlockScrollState(this.props.active);
      if (window && !this.props.active) {
        window.scrollTo(0, this.state.scrollY);
      }
    }
  }

  render() {
    const { active, setPricesPageActive, isPricesPageActive } = this.props;

    return (
      <div
        ref={this.container}
        className={classnames(styles.pricesPage, {
          [styles.active]: active,
          [styles.loaded]: this.state.mounted
        })}
      >
        <HamburgerIcon
          onClick={() => setPricesPageActive(!isPricesPageActive)}
          className={styles.hamburger}
          active={isPricesPageActive}
        />

        <section className={styles.container}>
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Heren"
            svg={require("svg/product-category-heren.svg")}
            items={[
              { text: "Wassen / Knippen / Drogen", price: 20.0 },
              { text: "Wassen / Knippen / Drogen / Lotion", price: 22.5 },
              { text: "Baard Trimmen", price: 10.0 },
              { text: "Opscheren / Tondeuse kort", price: 15.0 }
            ]}
          />
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Dames"
            svg={require("svg/product-category-dames.svg")}
            items={[
              { text: "Wassen / Knippen / Drogen", price: 20.0 },
              { text: "Wassen / Knippen / Modelföhnen", price: 32.5 },
              { text: "Wassen / Knippen / Watergolven", price: 32.5 },
              {
                text: "Wassen / Föhnen / Watergolven",
                price: 17.5,
                fromPrice: true
              },
              { text: "Pony Knippen", price: 7.5 }
            ]}
          />
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Kids t/m 10 Jaar"
            svg={require("svg/product-category-kids.svg")}
            items={[{ text: "Knippen / Drogen", price: 15.0 }]}
          />
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Verven"
            svg={require("svg/product-category-verven.svg")}
            items={[
              { text: "Verven Uitgroei", price: 31.5 },
              { text: "Verven Kort haar", price: 34.5 },
              { text: "Verven Lang haar", price: 44.5, fromPrice: true },
              { text: "Elumen Kleuring", price: 34.5, fromPrice: true },
              { text: "Coupe Soleil Kort Haar", price: 34.5 },
              { text: "Coupe Soleil Lang Haar", price: 44.5, fromPrice: true },
              { text: "Toner Kort Haar", price: 29.5 },
              { text: "Toner Lang Haar", price: 39.5 },
              { text: "Toeslag Lang Haar", price: 17.50 }
            ]}
          />
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Permanent All-In"
            svg={require("svg/product-category-permanent.svg")}
            items={[
              { text: "Permanent", price: 72.5 },
              { text: "Deel Permanent", price: 60.0 },
              { text: "Toeslag Lang Haar", price: 17.50 }
            ]}
          />
          <PriceCategoryList
            className={styles.priceCategoryList}
            title="Specials"
            svg={require("svg/product-category-specials.svg")}
            items={[
              { text: "Hair-Extensions", onRequest: true },
              { text: "Opsteken", price: 30.0 },
              { text: "Wenkbrauwen Epileren", price: 7.5 },
              { text: "Wenkbrauwen Verven", price: 7.5 }
            ]}
          />
        </section>
      </div>
    );
  }
}

export default compose(withApplicationState)(PriceList);
